import React from 'react';
import styled, { css } from 'styled-components';
import { Button, Tooltip, ActionsBar } from '@ozlydev/ozly-sand-ui';
import { useDispatch, useSelector } from 'react-redux';
import { openSidebar, closeSidebar } from '../../../redux/sidebar/reducer';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router-dom';
import { getAgentFromUrl } from 'utils/urlSeo';

const Container = styled.div`
 border-radius: 40px;
 margin-left: 16px;
 display: flex;
 flex-direction: column;
 background: #fff;
 padding: 4px;
 z-index: 20;
 position: relative;

 ${(props) => !props.$ischat && css`
    position: absolute;
    right: 24px;
    top: 24px;
 `}

 @media (max-width: 768px) {
    position: fixed;
    right: 16px;
    top: 16px;
    flex-direction: row;
    height: auto;

    > div > button {
        margin-right: 8px;
    }

    > div:last-child > button {
        margin-right: 0px;
    }

  }
`;

const ChatMenu = ({ }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const mainSidebar = useSelector(state => state.sidebar.main);
    const agent = useSelector(state => state.agents?.characters?.find(a => a.pathNames?.includes(getAgentFromUrl())));
    // const isChat = (pathname, paths = ['/', '/dev']) => paths.includes(pathname);
    const isChat = (pathname, paths = ['/', '/dev']) => agent != null;

    if (agent?.menu?.display === false) {
        return <></>
    }

    return (<Container id="chatMenuContainer" $ischat={isChat(location.pathname, ['/'])}>
        {/* <Tooltip text="Parler à Ozly" position="bottom" variant="primary.reverse">
            <Button
                onClick={() => {
                    if (isChat(window.location.pathname)) {
                        dispatch(closeSidebar('main'))
                    } else {
                        dispatch(push('/'))
                    }
                }}
                size="medium"
                onlyicon="Chat"
                variant={((mainSidebar && isChat(window.location.pathname)) || !isChat(window.location.pathname)) ? 'ghost' : 'conversion'}
            >
                primary
            </Button>
        </Tooltip> */}

        <Tooltip text="Menu" position="bottom" variant="primary.reverse">
            <Button
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    dispatch(openSidebar('main', true, {
                        show: 'menu',
                    }));
                }}
                size="medium"
                onlyicon="Menu"
                variant={mainSidebar?.metadata?.show !== 'menu' ? 'ghost' : 'conversion'}
            >
                primary
            </Button>
        </Tooltip>
    </Container>);
}

export default ChatMenu;
