import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import axios from 'axios';
import { getUser, loginToken } from './actions';
import { showAndRemove } from '../../redux/toasts/actions';
import { connectSocket } from '../../redux/socket/saga';
import { connectSocket as connectSocketLangServe } from '../../redux/socketLangServe/saga';
import { initUserAnalytics } from '../../modules/analytics';
import { getChatHistory } from '../../redux/chat/actions';
import { getAgentsCharacters } from '../../redux/agents/actions';
import { getAgentFromUrl } from 'utils/urlSeo';

function* showChangePasswordToast() {
  yield put(showAndRemove('Tu peux modifier ton mot de passe', {
    delay: 10000,
    actionLabel: 'Changer',
    actionRedirect: '/settings',
  }));
}

const redirectTo = () => {
  return window.location.href === '/login' ? '/' : getAgentFromUrl();
}

export function* onMagicLinkSuccess() {
  yield call(apiCall, getUser({ 
    redirect: redirectTo(),
    event: 'login',
    origin: 'magic_link' }));
  yield call(showChangePasswordToast);
}

export function* onEmailValidationSuccess() {
  yield call(apiCall, getUser({ 
    redirect: redirectTo(),
    event: 'login',
    origin: 'validation_email' }));
  yield call(showChangePasswordToast);
}

export function* onGoogleAuthSuccess() {
  yield call(apiCall, getUser({ 
    redirect: redirectTo(),
    event: 'login',
    origin: 'google_auth' }));
}

export function* onLinkedinAuthSuccess() {
  yield call(apiCall, getUser({
    redirect: redirectTo(),
    event: 'login',
    origin: 'linkedin_auth' }));
}

export function* onLoginSuccess() {
  yield call(apiCall, getUser({ 
      redirect: redirectTo(),
      event: 'login',
      origin: 'login',
    }));
}

export function* onRegisterSuccess() {
  yield call(apiCall, getUser({ 
      redirect: redirectTo(),
      event: 'sign_up',
      origin: 'sign_up',
    }));
}

export function* onLoginTokenAuto({ payload }) {
  yield call(apiCall, loginToken(payload.token));
  yield call(apiCall, getUser({ redirect: redirectTo(), type: 'login', origin: 'login_token'}));
}

export function* onGetUserSuccess({ payload, initial }) {
  // console.log('postMessage SSAGA', action.payload.sessionToken);
  // window.postMessage(
  //   { type: 'SESSION_TOKEN', token: action.payload.sessionToken, source: 'ozly' },
  //   '*',
  // );

  if (initial?.body?.origin && initial?.body?.event) {
    const event = initial.body.event;

    if (payload?.isSignUp) { // do not forget to return this from the backend for google / linkedin
        event = 'sign_up';
    }

    initUserAnalytics(payload.user, event, initial.body.origin);
  }

  yield put(connectSocket());
  yield put(connectSocketLangServe());

  yield put(getAgentsCharacters());
   
  if (payload.user && initial.body.redirect) {
    yield put(push(initial.body.redirect));
  } else if (payload.user && window.location?.pathname.search('/login') !== -1 && payload?.user?.type !== 'guest') {
    yield put(push(redirectTo()));
  }
}

function* callApi(url, method, body, query, langServe) {
  try {
    const user_id = yield select(state => state.global.user?._id);
    const options = {
      method,
      data: body,
      params: query,
      headers: {
        'Content-Type': 'application/json',
        ...langServe ? {
          'session-id': user_id,
        } : {},
        originfrom: getAgentFromUrl(),
      },
      withCredentials: true,
    };

    const response = yield call(axios, url, options);
    return response.data;
  } catch (err) {
    throw err;
  }
}

function* apiCall(action) {
  const { type, baseUrl, requestType, body, query, langServe, ...rest } = action;
  const [REQUEST, SUCCESS, FAILURE] = type;

  try {

    yield put({ type: REQUEST, payload: { body, query }, ...rest });

    const BASE_URL = langServe ? `${process.env.LANG_SERVE_URL}${baseUrl}` : `${process.env.API_URL}${baseUrl}`;
  
    const response = yield callApi(
      BASE_URL,
      requestType,
      body,
      query,
      langServe,
    );

    yield put({ type: SUCCESS, payload: response, initial: {
      body,
      // query
    }, ...rest });
  } catch (error) {
    yield put({
      type: FAILURE,
      error: {
        status: error?.response?.status,
        statusText: error?.response?.statusText,
        message: error?.message,
        data: error?.response?.data
      },
      ...rest
    });
  }
}


export default function* githubData() {
  yield takeLatest('LOGIN_TOKEN_AUTO', onLoginTokenAuto);

  yield takeLatest('LOGIN_MAGIC_LINK_SUCCESS', onMagicLinkSuccess);
  yield takeLatest('EMAIL_VALIDATION_SUCCESS', onEmailValidationSuccess);
  yield takeLatest('LOGIN_SUCCESS', onLoginSuccess);

  yield takeLatest('REGISTER_SUCCESS', onRegisterSuccess);

  yield takeLatest('AUTHENTICATE_GOOGLE_SUCCESS', onLoginSuccess);
  yield takeLatest('AUTHENTICATE_LINKEDIN_SUCCESS', onLoginSuccess);
  
  yield takeEvery('GET_USER_SUCCESS', onGetUserSuccess);

  yield takeLatest(action => {
    return (Array.isArray(action.type) && !action?.takeEvery) ;
  }, apiCall);

  yield takeEvery(action => {
    return (action?.takeEvery && Array.isArray(action.type)) ;
  }, apiCall);

  yield call(apiCall, getUser({
    event: 'login',
    origin: 'login_cookie'
  }));
}