/*
 * App Actions
 */

import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  GET_USER,
  SEARCH_JOBS,
  SEARCH_JOBS_SUCCESS,
  SEARCH_JOBS_FAIL,
  SEARCH_JOBS_HISTORY,
  SEARCH_JOBS_HISTORY_SUCCESS,
  SEARCH_JOBS_HISTORY_FAIL,
  SEND_MAGIC_LINK,
  SEND_MAGIC_LINK_SUCCESS,
  SEND_MAGIC_LINK_FAIL,
  LOGIN_MAGIC_LINK,
  LOGIN_MAGIC_LINK_SUCCESS,
  LOGIN_MAGIC_LINK_FAIL,
  EMAIL_VALIDATION,
  EMAIL_VALIDATION_SUCCESS,
  EMAIL_VALIDATION_FAIL,
  ALERT_EMAIL_VALIDATION,
  ALERT_EMAIL_VALIDATION_SUCCESS,
  ALERT_EMAIL_VALIDATION_FAIL,
  LOGIN_TOKEN,
  LOGIN_TOKEN_SUCCESS,
  LOGIN_TOKEN_FAIL,
  RESET_SEARCH,
  FOCUS_PROMPT,
  SET_CAROUSEL_CURRENT_INDEX,
  NEW_PASSWORD,
  NEW_PASSWORD_SUCCESS,
  NEW_PASSWORD_FAIL
} from './constants';

export function loginToken(token) {
  return {
    type: [LOGIN_TOKEN, LOGIN_TOKEN_SUCCESS, LOGIN_TOKEN_FAIL],
    baseUrl: '/api/auth/login/token',
    requestType: 'post',
    body: {
      token,
    },
    analytics: {
        on: 'success',
        amplitude: {
            event: 'login'
        }
    },
    dialogs: {
      closeAll: true,
    },
  };
}

export function login(body) {
  return {
    type: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
    baseUrl: '/api/auth/login',
    requestType: 'post',
    body,
    loader: 'login',
    dialogs: {
      close: 'login',
    },
    analytics: {
        on: 'success',
        amplitude: {
            event: 'login'
        }
    },
    toasts: {
      success: {
        message: 'Connexion réussie :)',
      },
      fail: {
        message: 'La connexion a échouée :(',
      },
    }
  };
}

export function register(body) {
  return {
    type: [REGISTER, REGISTER_SUCCESS, REGISTER_FAIL],
    baseUrl: '/api/auth/register',
    requestType: 'post',
    body,
    loader: 'register',
    dialogs: {
      close: 'register',
    },
    analytics: {
        on: 'success',
        amplitude: {
            event: 'sign_up'
        }
    },
    toasts: {
      success: {
        message: 'Ton compte a bien été créé :)',
      },
      fail: {
        message: 'Ton compte n’a pas pu être créé :(',
      },
    },
  };
}

export function authenticateWithLinkedIn() {
  window.open('https://api.ozly.ai/api/auth/linkedin', '_blank');
}

export function authenticateWithGoogle() {
  window.open('https://api.ozly.ai/api/auth/google', '_blank');
}

export function sendMagicLink(email) {
  return {
    type: [SEND_MAGIC_LINK, SEND_MAGIC_LINK_SUCCESS, SEND_MAGIC_LINK_FAIL],
    baseUrl: '/api/auth/magiclink',
    requestType: 'post',
    body: { email },
    loader: 'sendMagicLink',
    dialogs: {
      closeAll: true,
      open: 'magicLinkSent'
    },
    toasts: {
      success: {
        message: 'Un lien magique t\'a été envoyé par email :)',
      },
      fail: {
        message: 'L’envoi du lien magique a échoué :(',
      },
    },
  };
}

export function loginMagicLink(token) {
  return {
    type: [LOGIN_MAGIC_LINK, LOGIN_MAGIC_LINK_SUCCESS, LOGIN_MAGIC_LINK_FAIL],
    baseUrl: `/api/auth/magiclink/${token}`,
    requestType: 'get',
    loader: 'loginMagicLink',
    dialogs: {
      closeAll: true,
      fail: {
        open: 'lostPassword'
      }
    },
    redirect: {
      fail: '/',
    },
    toasts: {
      success: {
        message: 'You have successfully logged in with a magic link.',
      },
      fail: {
        message: 'There was an error logging in with a magic link.',
      },
    },
  };
}

export function emailValidation(token) {
  return {
    type: [EMAIL_VALIDATION, EMAIL_VALIDATION_SUCCESS, EMAIL_VALIDATION_FAIL],
    baseUrl: `/api/auth/email/${token}`,
    requestType: 'get',
    loader: 'emailValidation',
    dialogs: {
      closeAll: true,
      fail: {
        open: 'lostPassword'
      }
    },
    analytics: {
        on: 'success',
        amplitude: {
            event: 'login'
        }
    },
    redirect: {
      fail: '/',
      success: '/'
    },
    toasts: {
      success: {
        message: 'Tu as été connecté(e) :)',
      },
      fail: {
        message: 'Un problème est survenu durant la validation de ton email.',
      },
    },
  };
}

export function alertEmailValidation(token) {
  return {
    type: [ALERT_EMAIL_VALIDATION, ALERT_EMAIL_VALIDATION_SUCCESS, ALERT_EMAIL_VALIDATION_FAIL],
    baseUrl: `/api/alerts/validate/${token}`,
    requestType: 'put',
    loader: 'alertEmailValidation',
    dialogs: {
      closeAll: true,
    },
    analytics: {
        on: 'success',
        amplitude: {
            event: 'alert_email_validation'
        }
    },
    redirect: {
      fail: '/',
      success: '/'
    },
    toasts: {
      success: {
        message: 'Ton alerte a bien été validée :)',
      },
      fail: {
        message: 'Un problème est survenu durant la validation de ton alerte.',
      },
    },
  };
}

export function logout() {
  return {
    type: [LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAIL],
    baseUrl: '/api/auth/logout',
    requestType: 'post',
    loader: 'logout',
    redirect: {
      success: '/',
    },
    toasts: {
      success: {
        message: 'Tu as été déconnecté(e) :)',
      },
      fail: {
        message: 'Tu n’as pas pu être déconnecté(e) :(',
      },
    },
  };
}

export function getUser(options) {
  return {
    type: [GET_USER, GET_USER_SUCCESS, GET_USER_FAIL],
    baseUrl: '/api/auth/user',
    requestType: 'get',
    body: options,
  };
}

export function searchJobs(text) {
  return {
    type: [SEARCH_JOBS, SEARCH_JOBS_SUCCESS, SEARCH_JOBS_FAIL],
    baseUrl: '/api/search',
    requestType: 'post',
    loader: 'searchJobs',
    body: {
      text,
    },
  };
}

export function searchHistory() {
  return {
    type: [SEARCH_JOBS_HISTORY, SEARCH_JOBS_HISTORY_SUCCESS, SEARCH_JOBS_HISTORY_FAIL],
    baseUrl: '/api/search/history',
    requestType: 'get',
    loader: 'searchJobsHistory',
    toasts: {
      fail: {
        message: 'Votre historique de recherche n’a pas pu être récupéré :(',
      },
    }
  };
}

export function resetSearch() {
  return {
    type: RESET_SEARCH, 
  };
}

export function focusPrompt(value = undefined) {
  return {
    type: FOCUS_PROMPT,
    payload: {
      value,
    }
  };
}

export function setCarouselCurrentIndex(index, groupIndexes, groupType, groupValue) {
  return {
    type: SET_CAROUSEL_CURRENT_INDEX,
    payload: {
      index,
      groupIndexes,
      groupType,
      groupValue,
    }
  };
}


